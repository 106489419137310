@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap);
.active{
  background: rgba(18, 115, 183, 0.1);
  border-radius: 10px;
  color: #3f52fd !important;
  font-weight: 600;
}

.owl-item.active{
  border-radius: 20px !important;
  background: rgba(2, 7, 29, 0.1) !important;
}

.owl-dot{
  padding: 0 !important;
}
.owl-dot.active{
  width: 30px;
  /*margin: 0;*/
  padding: 0 !important;
  margin: 0 3px !important;
}
.owl-dot  span{
  width: 8px !important;
  height: 8px !important;
  background: rgba(255, 255, 255, 0.2) !important;
  margin: 5px !important;
  padding: 0 !important;
}

.owl-dot.active span{
  width: 25px !important;
  height: 8px !important;
  background: #fff !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  font-family: Roboto, Poppins, Ubuntu, sans-serif;
}

